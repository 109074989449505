
























































































































































































































import {Component, Vue, Watch} from 'vue-property-decorator';
import UserAvatar from '@/components/user/UserAvatar.vue';
// import PostComment from '@/components/chat/PostComment.vue';
const PostComment = () => import('@/components/chat/PostComment.vue');
import {BoardMessage, BoardMessagePaginator, Reaction} from '@/typescript/types';
// import ChatMessage from '@/components/chat/ChatMessage.vue';
const ChatMessage = () => import('@/components/chat/ChatMessage.vue');
import {
  BoardMessageDetailedFragment,
  BoardMessagesQuery,
  BoardQuery,
  UpdateLastSeenMessageMutation,
  RemoveUnseenBoardMessageMutation
} from '@/graphql/board';
import gql from 'graphql-tag';
//import LayoutFrame from "@/components/layout/LayoutFrame.vue";
const LayoutFrame = () => import("@/components/layout/LayoutFrame.vue");
// import ChatLoader from "@/components/loaders/ChatLoader.vue";
const ChatLoader = () => import("@/components/loaders/ChatLoader.vue");
import Encryption from '@/encryption';
import InfiniteLoading from 'vue-infinite-loading';
// import {BoardNotificationsQuery} from "@/graphql/BoardNotification";
// import {ReadAllBoardNotificationsMutation} from "@/graphql/board";
// import BoardSettings from "@/components/board/utilities/BoardSettings.vue";
const BoardSettings = () => import("@/components/board/utilities/BoardSettings.vue");
// import RoomCenterStage from "@/components/room/RoomCenterStage.vue";
const RoomCenterStage = () => import("@/components/room/RoomCenterStage.vue");
// import RoomPopInput from "@/components/room/RoomPopInput.vue";
const RoomPopInput = () => import("@/components/room/RoomPopInput.vue");
import {
  GetAllWorkspaceNotificationForUser,
  ReadAllWorkspaceNotificationsForBoard,
} from "@/graphql/WorkspaceNotification";
import Paywall from "@/components/subscription/Paywall.vue";
import OnboardingVideoTutorial from "../workspace/OnboardingVideoTutorial.vue";
// const OnboardingVideoTutorial = () => import("../workspace/OnboardingVideoTutorial.vue");
import UserProfile from "@/components/user/profile/UserProfile.vue";
import BoardMembers from "@/components/board/BoardMembers.vue";


@Component({
  components: {
    RoomPopInput,
    RoomCenterStage,
    BoardSettings, ChatLoader, LayoutFrame, ChatMessage, PostComment, UserAvatar, InfiniteLoading, OnboardingVideoTutorial},
  apollo: {
    getMessagesForBoard: {
      fetchPolicy: 'network-only',
      query: BoardMessagesQuery,
      subscribeToMore: {
        document: gql`
                    subscription BoardMessageUpdated($board_id: ID!) {
                        boardMessageUpdated(board_id: $board_id) {
                            ...BoardMessageDetailed
                        }
                    }
                    ${BoardMessageDetailedFragment}
                `,
        skip() {
          return !this.board;
        },
        variables() {
          return {
            board_id: this.board.id,
          };
        },
        updateQuery(previous, {subscriptionData}) {

          this.newCount++;
          this.alertNewMessage = true;

          if(Notification.permission != "denied" && Notification.permission != 'granted') {
            this.$events.fire('open-browser-notofication-permission')
          }

          let previousMessages = previous.getMessagesForBoard.data;
          let newMessage = subscriptionData.data.boardMessageUpdated;

          if (newMessage.board.pid !== this.$store.state.active_board_pid) {
            throw new Error('Message was unsuccessfully decrypted')
          }

          let duplicate = previousMessages.findIndex((chatMessage: BoardMessage) => {
            return chatMessage.id === newMessage.id
          })

          if (duplicate != -1) {
            previousMessages = previousMessages.filter((chatMessage: BoardMessage) => {
              return chatMessage.id !== duplicate.id
            })
          }

          if (newMessage.deleted_at !== undefined && newMessage.deleted_at) {
            this.deleteMessage(newMessage.id);
          } else if (duplicate != -1) {
            previousMessages[duplicate] = newMessage;
          } else {
            previousMessages.unshift(newMessage);
            if (this.$store.state.playSound && this.$store.state.active_board_pid && newMessage.sender.id != this.me.id) {
              this.playSound();
            }
          }

          return {
            getMessagesForBoard: {
              data: previousMessages,
              paginatorInfo: previous.getMessagesForBoard.paginatorInfo,
              __typename: previous.getMessagesForBoard.__typename,
            },
          };
        },
      },
      result() {
        if(this.page === 1){
          this.$nextTick(function () {
            //@ts-ignore
            this.scrollToEnd('instant');
          });
        }

      },
      skip() {
        return !this.board;
      },
      variables() {
        return {
          id: this.board.id,
        };
      },
    },
  },
})
export default class Chat extends Vue {
  page = 1;
  newCount = 0;
  getMessagesForBoard: BoardMessagePaginator | null = null;
  alertNewMessage = false;
  infiniteStateUp: any | null = null;
  divHeight: number | null = null;
  showUnreadButton: boolean = false;
  infinitePreLoad: boolean = false;
  isLoading: boolean = false;
  initialLoad: boolean = true;
  open: boolean = false;
  firstLoad: number = 0;
  onboardingChat: number = 0;

  mounted() {
    this.$events.fire('chat-opened', {board_id:this.board.id,opened: true});
    this.$events.fire('removeNotification', this.board.id);
    this.$nextTick(() => {
      if(this.userLastSeenBoardMessage != null) {
        //@ts-ignore
        this.scrollToEnd('instant');
        this.removeUnseenBoardMessages();
      }
    });
    this.markAsRead();
    //setTimeout(() => { this.infinitePreLoad = true }, 1500);
    this.$events.listen('messageloded', (eventData) => {
      if(eventData === this.userLastSeenBoardMessage) {
        if(this.firstLoad == 0){
        
          this.$nextTick(() => {
            this.firstLoad++;
            this.scrollToId();
          });
        }
      }
    })

    if(this.displayOnboardingChat) {

      this.onboardingChat = 4
    
      // this.onboardingChat = 1
      // setTimeout(() => {
      //   this.onboardingChat = 2
      //   setTimeout(() => {
      //     this.onboardingChat = 3
      //     setTimeout(() => {
      //       this.onboardingChat = 4
      //       // setTimeout(() => {
      //       //   this.onboardingChat = 4
      //       // }, 10000)
      //     }, 5000)
      //   }, 10000)
      // }, 2000)
     
    }

    
    // this.$events.listen('template_onboarding_completed', (eventData) => {
    //   if(eventData) {
    //     this.onboardingChat = 0;
    //     setTimeout(() => {
    //       this.onboardingChat = 1
    //       setTimeout(() => {
    //         this.onboardingChat = 2
    //         setTimeout(() => {
    //           this.onboardingChat = 3
    //           setTimeout(() => {
    //             this.onboardingChat = 4
    //           // setTimeout(() => {
    //           //   this.onboardingChat = 4
    //           // }, 10000)
    //           }, 5000)
    //         }, 10000)
    //       }, 2000)
    //     }, 2000)
    //   }
    // })
  }

  beforeDestroy() {
    this.$store.commit('set_display_onboarding_chat', false);

  }

  created(){
    window.addEventListener('scroll', this.onScroll);
  }


  get me() {
    return this.$store.state.me;
  }

  get board() {
    return this.$store.state.board;
  }

  get userLastSeenBoardMessage() {
    let user = this.board.members.filter((user: any) => {
      return user.user.id === this.me.id;
    })

    return user[0].last_seen_board_message_id;
  }

  get workspace() {
    return this.$store.state.workspace;
  }

  get activitesCreated() {
    return this.workspace.totalObjectivesCount
  }

  get planList() {
    return this.$store.state.plans;
  }

  get workspaceSubscription() {
    return this.$store.state.workspace.currentSubscription;
  }

  get currentPlan() {
    if(this.workspaceSubscription && !this.workspaceSubscription.hasAccess) {
      return this.planList[0];
    }else if(this.workspaceSubscription == null) {
      return this.planList[0];
    }
    return this.workspaceSubscription.plan;
  }

  get hasFreemiumAccess() {
    return this.me.has_freemium_access
  }

  startChat() {
    this.$events.fire('template_onboarding_completed', true);
  }


  openPaywall() {
    this.$buefy.modal.open({
      component: Paywall,
      // @ts-ignore
      customClass: 'split-modal',
      parent: this,
    });
  }

  decrypt(message: string) {
    return Encryption.decrypt(message);
  }

  scrollToId() {
    const chatWindow = document.querySelector('.message_grid') as HTMLInputElement;
    const element = document.querySelector(".lastseenmessage") as HTMLInputElement;
    if(element) {
      var top = element.offsetTop;
      chatWindow.scrollTop = top
    }

  }

  onScroll(e: any) {
    if(e.target.scrollTop < !!this.divHeight){
      this.showUnreadButton = true;
    }
    if(e.target.scrollTop === 0 && this.hasMorePages){
      this.initialLoad = false;
      const chatWindow = document.querySelector('.message_grid') as HTMLInputElement;
      this.isLoading = true;
      chatWindow.scrollTop = e.target.clientHeight;
      this.changePage();
    }
  }
  compareDates(first: Date, second: Date) {
    let firstDate = new Date(first);
    let secondDate = new Date(second);

    let secondBetweenTwoDate = Math.abs((firstDate.getTime() - secondDate.getTime()) / 1000);

    return secondBetweenTwoDate >= 1000;
  }

  diffSeconds(first: Date, second: Date) {
    let firstDate = new Date(first);
    let secondDate = new Date(second);

    return Math.abs((firstDate.getTime() - secondDate.getTime()) / 1000);
  }

  expandChatFromParent() {
    this.$emit('expanded', true);
  }

  chatMessageLoaded() {
    this.$nextTick(() => {
      
      //this.scrollToEnd();
    });
  }

  previewLoaded() {
    if(this.initialLoad){
      this.$nextTick(() => {
        this.scrollToEnd('smooth');
      });
    }

  }

  openTutorialVideo() {
    this.$store.commit('set_display_onboarding_chat', false);
    this.$router.push({name: 'room' })
    this.$buefy.modal.open({
      component: OnboardingVideoTutorial,
      width: '1150px',
      canCancel: true,
      parent: this,
      customClass: 'video-tutorial'
    });
    this.$gtag.event('user_opened_onboarding_video_from_chat');
  }


  openFeedback() {
    this.$buefy.modal.open({
      component: UserProfile,
      // @ts-ignore
      customClass: 'is-paddingless user_profile_modal',
      props: {
        redirect: 'feedback'
      },
      width: '700px',
      parent: this,
    });
  }

  openBoardMembers() {
    this.$gtag.event('pmb_user_clicked_invite_prompt_in_chat');
    this.$buefy.modal.open({
      component: BoardMembers,
      width: '500px',
      parent: this,
    });
  }
  

  get activityIds() {
    let idArray = [this.board.id];
    for (let i = 0; i < this.board.objectives.length; i++) {
      idArray.push(this.board.objectives[i].id);
      for (let j = 0; j < this.board.objectives[i].key_results.data.length; j++) {
        idArray.push(this.board.objectives[i].key_results.data[j].id);
      }
    }
    return idArray;
  }

  get boardMessages() {
    if (this.getMessagesForBoard) {
      
      if(this.userLastSeenBoardMessage && this.$store.getters.getLastSeenMessageId == null) {
      
        let index = this.getMessagesForBoard.data.findIndex((message: any) => {
          return message.id === this.userLastSeenBoardMessage;
        });
        
        if(index == -1) {
          
          this.changePage();
        }else{
          this.$store.commit('set_last_seen_message', this.getMessagesForBoard!.data[0].id);
        }

      }
      this.$events.fire('chat-loaded', true);
      return [...this.getMessagesForBoard.data.slice().reverse()];
    }
    return [];
  }

  get hasMorePages() {
    if (this.getMessagesForBoard) {
      return this.getMessagesForBoard.paginatorInfo.hasMorePages;
    }
    return false;
  }

  get lastLoadedMessageId() {
    return this.getMessagesForBoard!.data[0].id;
  }

  get displayOnboardingChat() {
    return this.$store.state.display_onboarding_chat;
  }

  changePage() {
    this.page++;
    this.getMessagesForBoard!.data.splice(this.getMessagesForBoard!.data.length - this.newCount, this.newCount);
    this.newCount = 0;


    // setTimeout(() => {
    //
    //   this.infiniteStateUp.loaded();
    // }, 5000);

    this.$apollo.queries.getMessagesForBoard.fetchMore({
      variables: {
        page: this.page,
      },
      updateQuery: (previousResult, {fetchMoreResult}) => {
        //this.infiniteStateUp.loaded();
        this.isLoading = false;
        return {
          getMessagesForBoard: {
            __typename: previousResult.getMessagesForBoard.__typename,
            /**
             * Merging the tag list
             */
            data: [...previousResult.getMessagesForBoard.data, ...fetchMoreResult.getMessagesForBoard.data],
            paginatorInfo: fetchMoreResult.getMessagesForBoard.paginatorInfo,
          },
        };
      },
    });
  }

  alertNewMessageRead() {
    this.alertNewMessage = false;
    //this.scrollToEnd();
  }

  scrollToEnd(scrollMode: 'auto' | 'smooth' | undefined) {
    const chatWindow = document.querySelector('.message_grid') as HTMLElement;
    if (chatWindow) {
      chatWindow.scrollTo({
        top: chatWindow.scrollHeight - chatWindow.clientHeight,
        behavior: scrollMode || 'auto',
      });
      this.divHeight = chatWindow.scrollHeight + 5;
      this.newCount = 0;
      this.showUnreadButton = false;
    }
  }

  playSound() {
    if(this.$store.state.mute_notification_sound) {
      let audio = new Audio(require('@/assets/audio/chat-message.mp3'));
      audio.volume = 0.2;
      audio.play();
    }
  }

  newMessage(message: BoardMessage) {

    this.getMessagesForBoard!.data.unshift(message);
    this.$events.fire('removeNotification', this.board.id);
    this.newCount++;
    this.$nextTick(() => {
  
      this.scrollToEnd('smooth');
    });
  }

  updateMessage(update: any) {
    let replaceIndex = this.getMessagesForBoard!.data.findIndex((message: BoardMessage) => {
      return message.id === update.id
    });

    let messageToReplace = this.getMessagesForBoard!.data[replaceIndex]
    messageToReplace.id = update.boardMessage.id

    this.getMessagesForBoard!.data[replaceIndex] = messageToReplace;
  }

  deleteMessage(boardMessageId: string) {
    // this.getMessagesForBoard!.data = this.getMessagesForBoard!.data.filter((message: BoardMessage) => {
    //   return message.id !== boardMessageId
    // });
    this.getMessagesForBoard!.data.splice(this.getMessagesForBoard!.data.findIndex((message: BoardMessage) => message.id == boardMessageId),1)
    this.$store.commit('set_last_seen_message', this.getMessagesForBoard!.data[0].id);
    this.updateLastSeenMessage();
  }

  newReaction(reaction: Reaction, id: string) {
    let message = this.getMessagesForBoard!.data.find((message: BoardMessage) => {
      return message.id === id;
    });
    message?.reactions?.push(reaction);
  }

  markAsRead() {
    this.$apollo.mutate({
      mutation: ReadAllWorkspaceNotificationsForBoard,
      variables: {
        id: this.board.id
      },
      refetchQueries: [{query: GetAllWorkspaceNotificationForUser, variables: {id: this.$store.state.workspace.id}}],
    })
  }


  infiniteHandlerUp($state: any){
    this.infiniteStateUp = $state;
    this.changePage();
  }

  updateLastSeenMessage() {
    this.$apollo.mutate({
      mutation: UpdateLastSeenMessageMutation,
      variables: {
        user_id: this.me.id,
        board_id: this.board.id,
        chat_message_id:  this.$store.getters.getLastSeenMessageId
      },
      refetchQueries: [{query: BoardQuery, variables:{pid: this.board.pid}}]
    })
  }

  removeUnseenBoardMessages() {
    this.$apollo.mutate({
      mutation: RemoveUnseenBoardMessageMutation,
      variables: {
        board_id: this.board.id,
        user_id: this.me.id,
      },
      //refetchQueries: [{query: BoardMessagesQuery, variables: {id: (this.board) ? this.board.id : this.$props.linkedBoard.id}}, {query: BoardQuery, variables:{pid: (this.board) ? this.board.pid : this.$props.linkedBoard.pid}}, {query: WorkspaceQuery, variables:{pid: this.activeWorkspace.pid}},{query: BoardAttachments, variables:{board_id: this.board.id}}],
    }).catch((_) => {
        
    }).finally(() => {
      console.log('removed all');
    });

  }

  destroy() {
    this.$store.commit('set_last_seen_message', this.getMessagesForBoard!.data[0].id);
  }

  @Watch('loadChatLayout')
  onLoadChatLayoutChange() {
    this.$nextTick(() => {
   
      this.scrollToEnd('smooth');
    });
  }

  destroyed() {
    this.$store.commit('set_last_seen_message', this.getMessagesForBoard!.data[0].id);
  }



  @Watch('alertNewMessage')
  onalertNewMessageChange() {
    if (this.alertNewMessage) {
    
      this.markAsRead();
      const chatWindow = document.querySelector('.message-container-body') as HTMLInputElement;
      if (chatWindow != null) {
        if (Math.abs(chatWindow.scrollHeight - chatWindow.scrollTop - chatWindow.clientHeight) <= 40.0) {
          this.$nextTick(() => {
            
            this.scrollToEnd('smooth');

          });
          this.alertNewMessage = false;
        }
      }
    }
  }
}
